import UserGroupCardUtil,{IUserGroupCardDataObj,ICorpDetailData,IDeptDetailData,ITreeProp} from "@/views/sysviews/flow/userGroup/userGroupCardUtil";
import {ref, reactive, toRefs, onBeforeMount,onMounted, defineComponent, getCurrentInstance, computed, provide} from 'vue';

let treeProp:ITreeProp={
    children: 'children',
    label: 'label',
    id:'value'
}
const UserGroupCardHelper = defineComponent({
    name: 'UserGroupCard',
    title: "用户组",
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj:IUserGroupCardDataObj=reactive<IUserGroupCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            curOperGridRef:null,
            refMap:new Map(),
            curOperGrid:'',//点击明细表格的添加之后，控制当前正在操作的grid
            deptLoading:false,//打开按部门设置的时候，点击左边的机构树，右边展示正在加载遮罩层，来加载部门树
            dialogVisible:false,//点击按机构和按部门设置的时候，控制弹出框的显示与影藏

            corpDetailData:{//机构树对象
                treeData:[],//树的数据源
                selectItems:[] as any,//需要选中的树节点value集合
                defaultProps:treeProp //转换后台和树的属性对应关系
            },
            deptDetailData:{//部门树
                treeData:[],//树的数据源
                selectItems:[] as any,//当前已经勾选的树节点集合（上面机构是id集合，这里是节点）
                defaultProps:treeProp //转换后台和树的属性对应关系
            },
            activeTab:'first',//默认被激活的tab项
            //卡片传给dialog的初始化参数
            compParams: {
                modelPath: utils.Api.buildUrl("/userGroup"),
                hsDetails: true,
                details:['corpDetails','deptDetails','personDetails','roleDetails']
            },
            //表单属性
            form: {} as any,
            //表单验证规则
            rules: utils.UtilPub.commonFormValidRule([{name:'请输入用户组名称'},{password:'请选择关系条件'}])
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new UserGroupCardUtil(proxy,dataObj);
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                return [{label:'或者',value:1},{label:'并且',value:2}]
            }
        })
        //明细子表格的初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType:string) => {
                return {
                    detailParam: {
                        toolBar: [
                            {
                                id: "selfAdd",
                                title: "添加",
                                type: "primary",
                                icon: "el-icon-zoom-in",
                                clickEvent: 'addEvent',
                                param: detailType
                            }
                        ],
                        canAdd: false,//关闭明细表格自带的新增按钮，因为添加了自己的toolBar，在toolBar中有自己的新增按钮
                        canPage: false,
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: utils.Api.buildUrl("/userGroup/detail?t="+Math.random()*1000)
                    }
                }
            }
        })

        //点击用户组明细表格头部的添加按钮事件
        const addEvent=async(detailType:string)=>{
            await dataObj.utilInst.detailGridAddHandler(detailType);
        }

        //手动打开的弹出框，底部确认按钮的确认事件。（人员，角色弹出框确认事件）modelInst为手动打开的弹出框所包含的组件
        const sureHandler=async (modelInst:any)=>{
            return await dataObj.utilInst.person_role_dialogSureHandler(modelInst);
        }

        //机构部门弹出框确认事件
        const corpDeptSureHandler=async ()=>{
            await dataObj.utilInst.corp_dept_dialogSureHandler();
        }

        //按机构设置和按部门设置弹出框打开之后的回调方法
        //1、为机构的selectItems中存放当前已经选择的机构id，方便显示机构树的时候，自动勾选上已选择好的机构树节点
        //2、为部门的selectItems中存放当前已经选择的部门信息，不是id，而是一个对象，点击机构加载部门树的时候，把
        //   部门selectItems中的对象取出value，构造为一个数组，然后通过tree的setCheckedKeys方法设置上选中标志
        //这里主要就是为机构和部门构造已经选择好的数据项
        const opened=()=>{
            dataObj.utilInst.corp_dept_dialogInit();
        }

        //打开按部门设置之后，点击左边的机构树，动态根据机构id加载右边的部门树
        const corpClickChange=async(data:any)=>{
            await dataObj.utilInst.deptConfig_corpClickChange(data);
        }

        //打开按部门设置的时候，点击部门树节点事件
        const deptCheckChange=(data:any, checked:boolean, indeterminate:any)=>{
            dataObj.utilInst.deptConfig_deptCheckChange(data,checked,indeterminate);
        }

        //得到明细表格的保存数据并赋值给form的一个变量，方便后台接收
        const setDetailsJson=async()=>{
            await dataObj.utilInst.buildDetailSaveData();
        }

        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{

            return true;
        }
        return{
            ...toRefs(dataObj),comboSelect,cardDetailParam,addEvent,sureHandler,corpDeptSureHandler,
            opened,corpClickChange,deptCheckChange,setDetailsJson,beforeSaveHandler
        }
    },
    components: {}
});
export default UserGroupCardHelper;