import UserGroupCard from './UserGroupCard.vue';
import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance, computed} from 'vue';
import {TableColumnCtx} from "element-plus/es/components/table/src/table-column/defaults";

export default defineComponent({
    name: "UserGroupList",
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj=reactive({
            pageListRef:null,
            pageList: {
                queryParam: {
                    name:"",
                    relationCondition:""
                },
                modelComp: UserGroupCard,
                modelMethod: utils.Api.buildUrl("/userGroup/pageData")
            }
        })
        onMounted(()=>{

        })
        //---------------------------computed------------
        const comboSelect=computed(()=>{
            return (params:string) => {
                return [{label:'或者',value:'1'},{label:'并且',value:'2'}]
            }
        })
        const formatShow=(row:any, column:TableColumnCtx<any>, value:any, index:number)=>{
            return value==1?'或者':'并且';
        }
        return{
            ...toRefs(dataObj),comboSelect,formatShow
        }
    },
    components: {}
});