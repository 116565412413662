import BaseBean from "@/utils/BaseBean";
import PersonList from "@/views/sysviews/organization/person/PersonList.vue";
import RoleList from "@/views/sysviews/role/RoleList.vue";

export interface ITreeProp {
    children: string
    label: string
    id:string
}
export interface ICorpDetailData {
    treeData:Array<any>//树的数据源
    selectItems:Array<any>//需要选中的树节点value集合
    defaultProps: ITreeProp//转换后台和树的属性对应关系
}
export interface IDeptDetailData {
    treeData:Array<any>//树的数据源
    selectItems:Array<any>//需要选中的树节点value集合
    defaultProps: ITreeProp//转换后台和树的属性对应关系
}
export interface IUserGroupCardDataObj {
    utilInst:UserGroupCardUtil
    formRef:any
    curOperGridRef:any
    refMap:Map<string,any>
    curOperGrid:string//点击明细表格的添加之后，控制当前正在操作的grid
    deptLoading:boolean//打开按部门设置的时候，点击左边的机构树，右边展示正在加载遮罩层，来加载部门树
    dialogVisible:boolean//点击按机构和按部门设置的时候，控制弹出框的显示与影藏
    corpDetailData:ICorpDetailData//机构树对象
    deptDetailData:IDeptDetailData//部门树
    activeTab:string//默认被激活的tab项
    compParams: any  //卡片传给dialog的初始化参数
    form:any //表单属性
    rules:TFormRule  //表单验证规则
}

export default class UserGroupCardUtil extends BaseBean{
    public dataObj:IUserGroupCardDataObj;

    constructor(proxy:any,dataObj:IUserGroupCardDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //点击用户组明细表格头部的添加按钮事件
    public async detailGridAddHandler(detailType:string):Promise<void>{
        //记录下当前正在操作的明细表格，方便点击弹出框确认按钮的时候，为相应的明细表格做数据回填
        this.dataObj.curOperGrid=detailType;
        this.dataObj.curOperGridRef=this.dataObj.refMap.get(detailType);
        switch (detailType) {
            //如果是点击按机构设置和按部门设置，那么在点击添加按钮的时候，就把机构树的数据源准备好，然后显示dialog
            case 'corpDetails':
            case 'deptDetails':
                this.dataObj.corpDetailData.treeData= await this.utils.Api.corpTreeData();
                this.dataObj.dialogVisible=true;
                break;
            //如果是点击按人员设置和按角色设置，那么手动打开一个dialog，在dialog里面传入要显示的组件即可
            case 'personDetails':
                await this.utils.UtilPub.openDialog({title:"选择人员",modelComp:PersonList,proxy:this.proxy});
                break;
            case 'roleDetails':
                await this.utils.UtilPub.openDialog({title:"选择角色",modelComp:RoleList,proxy:this.proxy});
                break;
        }
    }
    //人员角色弹出框底部确认按钮事件。
    public async person_role_dialogSureHandler(modelInst:any):Promise<boolean>{
        //用户组明细表格中正在操作的明细表格实例对象
        let gridInst=this.dataObj.curOperGridRef;
        //手动打开的弹出框中，选择的人员或者角色项
        let selectRows = modelInst.pageListRef.getTbInst().getSelection();
        let type=3;
        if("roleDetails"==this.dataObj.curOperGrid)type=4
        switch (this.dataObj.curOperGrid) {
            case 'personDetails':
            case 'roleDetails':
                //循环弹出框中选择的行，如果在当前用户组明细表格中不存在则添加到明细表格中
                selectRows.forEach((item:any)=>{
                    if(this.checkExist(gridInst,item)==-1){
                        let row = {F_TYPE: type, F_TYPE_ID: item.F_ID,F_NAME:item.F_NAME};
                        gridInst.addRow(row);
                    }
                });
                break;
        }
        return true;
    }
    //机构部门弹出框确认事件
    public async corp_dept_dialogSureHandler():Promise<void>{
        //获取用户组明细正在操作的表格实例
        let gridInst=this.dataObj.curOperGridRef;
        //清空当前用户组明细正在操作的表格行，把已经选择上的数据添加到明细表格
        await gridInst.clearDetailData();
        switch (this.dataObj.curOperGrid) {
            case 'corpDetails':
                //或者已经选择上的机构树节点
                let selCorpNodes=this.dataObj.refMap.get('corpTreeRef').getCheckedNodes();
                //遍历已经选择上的节点数据添加到机构明细表格
                selCorpNodes.forEach((item:any)=> {
                    let row = {F_TYPE: 1, F_TYPE_ID: item.value,F_NAME:item.label};
                    gridInst.addRow(row);
                });
                break;
            case 'deptDetails':
                //和机构不一样，部门已经选择上的节点信息放到了this.deptDetailData.selectItems中
                //所以这里通过遍历selectItems来为部门表格添加数据
                //而且需要注意的是部门selectItems中存放的是树节点，机构selectItems中存放的是树value
                this.dataObj.deptDetailData.selectItems.forEach((item:any)=> {
                    let row = {F_TYPE: 2, F_TYPE_ID: item.value,F_NAME:item.label};
                    gridInst.addRow(row);
                });
                break;
        }
        this.dataObj.dialogVisible=false;
    }
    //在弹出框确认按钮事件中，将往人员和角色明细表格中添加弹出框中选择的数据，需要检查明细表格中人员、角色表格是否存在
    public checkExist(gridInst:any,val:any):number{
        return gridInst.getDetailData().findIndex((item:any)=>item.F_TYPE_ID==val.F_ID);
    }

    //机构部门弹出框中打开的时候，要把已经选择的机构、部门给勾选上
    public corp_dept_dialogInit():void{
        let gridInst=this.dataObj.curOperGridRef;
        let detailData=gridInst.getDetailData();
        if(this.dataObj.curOperGrid=="corpDetails"){
            this.dataObj.corpDetailData.selectItems=[];
            detailData.forEach((item:any)=> {
                this.dataObj.corpDetailData.selectItems.push(item.F_TYPE_ID);
            });
        }
        if(this.dataObj.curOperGrid=="deptDetails"){
            this.dataObj.deptDetailData.selectItems=[];
            //第一次打开按部门设置弹出框，右边不会有部门树，如果不是第一次打开，则会同时出现机构树、部门树、这个时候应该为部门树把已选中的节点给勾选上
            let nodeValues=[] as any;//已选中部门节点的id数组
            detailData.forEach((item:any)=>{
                //已选中部门节点信息
                this.dataObj.deptDetailData.selectItems.push({F_TYPE: 2,value:item.F_TYPE_ID,label:item.F_NAME});
                nodeValues.push(item.F_TYPE_ID);
            });
            //如果不是第一次打开部门树，则refMap中会存有部门树信息
            if(this.dataObj.refMap.has('deptTreeRef'))this.dataObj.refMap.get('deptTreeRef').setCheckedKeys(nodeValues);
        }
    }


    //打开按部门设置之后，点击左边的机构树，动态根据机构id加载右边的部门树
    public async deptConfig_corpClickChange(data:any):Promise<void>{
        //打开右边部门树的遮罩层
        this.dataObj.deptLoading=true;
        //根据机构id重新加载部门数据源
        this.dataObj.deptDetailData.treeData = await this.utils.Api.deptTreeData({
            corpId: data.value
        });
        //根据部门树中的selectItems，构造好需要选中的部门树节点value集合
        let nodeValues=this.dataObj.deptDetailData.selectItems.map((item:any)=>item.value);
        //设置部门树的选中节点
        this.dataObj.refMap.get('deptTreeRef').setCheckedKeys(nodeValues);
        //关闭右边部门树的遮罩层
        this.dataObj.deptLoading=false;
    }
    //打开按部门设置之后，点击左边的机构树，动态根据机构id加载右边的部门树
    public deptConfig_deptCheckChange(data:any, checked:boolean, indeterminate:any):void{
        //查找当前操作的树节点是否已经被添加到已选selectItems数组中了，然后根据选中状态来判断是添加还是删除
        let result=this.dataObj.deptDetailData.selectItems.findIndex((item:any)=>item.value==data.value);
        if(checked && result==-1){//树节点是选中，但是还未在以选中数组中，则把它添加到已选中数组中
            this.dataObj.deptDetailData.selectItems.push(data)
        }else if(!checked && result!=-1){//树节点取消选中，但是还在已选中数组中，则把它从已选中数组中移除
            this.dataObj.deptDetailData.selectItems.splice(result, 1);
        }
    }

    //构建保存数据，全部弄成insert，在后台的时候会先delete，然后insert
    public async buildDetailSaveData():Promise<void>{
        //把明细表格数据全部放到新增数组里面
        await this.setSaveData(this.dataObj.refMap.get('corpDetails'));
        await this.setSaveData(this.dataObj.refMap.get('deptDetails'));
        await this.setSaveData(this.dataObj.refMap.get('personDetails'));
        await this.setSaveData(this.dataObj.refMap.get('roleDetails'));
        this.dataObj.form["corpDetails"] =await this.dataObj.refMap.get('corpDetails').getSaveDetailData();
        this.dataObj.form["deptDetails"] =await this.dataObj.refMap.get('deptDetails').getSaveDetailData();
        this.dataObj.form["personDetails"] =await this.dataObj.refMap.get('personDetails').getSaveDetailData();
        this.dataObj.form["roleDetails"] =await this.dataObj.refMap.get('roleDetails').getSaveDetailData();
    }
    //因为用户组后台保存明细的时候，是先删除旧数据，再把本次的数据全部新增，所以这里需要把所有的数据全部整成新增状态
    public setSaveData(gridInst:any):Promise<boolean>{
        return new Promise((resolve, reject) => {
            let tbData = gridInst.getDetailData();//根据表格实例对象得到表格的所有明细数据
            tbData.forEach((item:any)=> {
                //把表格所有明细数据全部设置为插入状态，后台处理的时候，是先删掉就数据，在插入新的数据，所以前台这样处理，否则后台要考虑删除和更新的情况
                gridInst.setInsert(item);
            });
            resolve(true);
        })
    }
}